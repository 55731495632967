<template>
    
    <div class="page__content">

        <HeaderBody/>

        <!-- NO CARD -->
        <div class="col-12" v-if="card=='none' || card==''">
            <div class="row" style="margin-top: 20px; margin-bottom: 50px;">
                <div class="col-12 col-xl-4">

                    <div style="display: flex; justify-content: center; align-items: center; flex-direction: column;">
                        <img src="@/assets/dashAssets/img/nebula.png" alt="" style="width: 390px;">
                        <button class="operations__btn btn btn_blue " style="margin-top: 20px; margin-bottom: 20px;" @click="openDelivery('Nebula')">Get Nebula Card</button>

                    </div>

                    

                </div>

                <div class="col-12 col-xl-7">

                    <div style="display: flex; justify-content: center; align-items: center; ">

                        <div class="details__list">

                            <div class="details__box">
                                
                                <div class="details__inner">
                                <div class="details__category">Card tier<svg class="icon icon-info-circle">
                                    <use xlink:href="img/sprite.svg#icon-info-circle"></use>
                                    </svg></div>
                                <div class="details__price">Nebula</div>
                                </div>
                            </div>

                            <div class="details__box">
                                
                                <div class="details__inner">
                                <div class="details__category">Cashback Reward<svg class="icon icon-info-circle">
                                    <use xlink:href="img/sprite.svg#icon-info-circle"></use>
                                    </svg></div>
                                <div class="details__price" style="color: #4ABA67;">4.2%</div>
                                </div>
                            </div>

                            <div class="details__box">
                                <!-- <div class="details__icon"><img src="img/analytics-blue.svg" alt=""></div> -->
                                <div class="details__inner">
                                <div class="details__category">Free Monthly ATM Withdrawals<svg class="icon icon-info-circle">
                                    <use xlink:href="img/sprite.svg#icon-info-circle"></use>
                                    </svg></div>
                                <div class="details__price">1,000 USD</div>
                                </div>
                            </div>

                            <div class="details__box mm-none">
                                
                                <div class="details__inner">
                                <div class="details__category">Monthly ATM Withdrawal Limit<svg class="icon icon-info-circle">
                                    <use xlink:href="img/sprite.svg#icon-info-circle"></use>
                                    </svg></div>
                                <div class="details__price">10,000 USD</div>
                                </div>
                            </div>

                            <div class="details__box mm-none">
                                
                                <div class="details__inner">
                                <div class="details__category">Aggregated Top-up Monthly Limit<svg class="icon icon-info-circle">
                                    <use xlink:href="img/sprite.svg#icon-info-circle"></use>
                                    </svg></div>
                                <div class="details__price">25,000 USD</div>
                                </div>
                            </div>

                            <div class="details__box mm-none">
                                
                                <div class="details__inner">
                                <div class="details__category">Netflix<svg class="icon icon-info-circle">
                                    <use xlink:href="img/sprite.svg#icon-info-circle"></use>
                                    </svg></div>
                                <div class="details__price">1 month free</div>
                                </div>
                            </div>

                        </div>

                        

                    </div>

                </div>

            </div>

            <div class="row" style="margin-top: 20px; margin-bottom: 50px;">
                <div class="col-12 col-xl-4">

                    <div style="display: flex; justify-content: center; align-items: center; flex-direction: column;">
                        <img src="@/assets/dashAssets/img/quasa.png" alt="" style="width: 390px;">
                        <button class="operations__btn btn btn_blue" style="margin-top: 20px; margin-bottom: 20px;" @click="openDelivery('Quasa')">Get Quasa Card</button>

                    </div>

                    

                </div>

                <div class="col-12 col-xl-7">

                    <div style="display: flex; justify-content: center; align-items: center; ">

                        <div class="details__list">

                            <div class="details__box">
                                
                                <div class="details__inner">
                                <div class="details__category">Card tier<svg class="icon icon-info-circle">
                                    <use xlink:href="img/sprite.svg#icon-info-circle"></use>
                                    </svg></div>
                                <div class="details__price">Quasa</div>
                                </div>
                            </div>

                            <div class="details__box">
                                
                                <div class="details__inner">
                                <div class="details__category">Cashback Reward<svg class="icon icon-info-circle">
                                    <use xlink:href="img/sprite.svg#icon-info-circle"></use>
                                    </svg></div>
                                <div class="details__price" style="color: #4ABA67;">6.7%</div>
                                </div>
                            </div>

                            <div class="details__box">
                                <!-- <div class="details__icon"><img src="img/analytics-blue.svg" alt=""></div> -->
                                <div class="details__inner">
                                <div class="details__category">Free Monthly ATM Withdrawals<svg class="icon icon-info-circle">
                                    <use xlink:href="img/sprite.svg#icon-info-circle"></use>
                                    </svg></div>
                                <div class="details__price">3,000 USD</div>
                                </div>
                            </div>

                            <div class="details__box mm-none">
                                
                                <div class="details__inner">
                                <div class="details__category">Monthly ATM Withdrawal Limit<svg class="icon icon-info-circle">
                                    <use xlink:href="img/sprite.svg#icon-info-circle"></use>
                                    </svg></div>
                                <div class="details__price">15,000 USD</div>
                                </div>
                            </div>

                            <div class="details__box mm-none">
                                
                                <div class="details__inner">
                                <div class="details__category">Aggregated Top-up Monthly Limit<svg class="icon icon-info-circle">
                                    <use xlink:href="img/sprite.svg#icon-info-circle"></use>
                                    </svg></div>
                                <div class="details__price">25,000 USD</div>
                                </div>
                            </div>

                            <div class="details__box mm-none">
                                
                                <div class="details__inner">
                                <div class="details__category">Netflix<svg class="icon icon-info-circle">
                                    <use xlink:href="img/sprite.svg#icon-info-circle"></use>
                                    </svg></div>
                                <div class="details__price">1 month free</div>
                                </div>
                            </div>

                            <div class="details__box mm-none">
                                
                                <div class="details__inner">
                                <div class="details__category">Spotify<svg class="icon icon-info-circle">
                                    <use xlink:href="img/sprite.svg#icon-info-circle"></use>
                                    </svg></div>
                                <div class="details__price">1 month free</div>
                                </div>
                            </div>

                            

                        </div>

                        

                    </div>

                </div>

            </div>

            <div class="row" style="margin-top: 20px; margin-bottom: 50px;">
                <div class="col-12 col-xl-4">

                    <div style="display: flex; justify-content: center; align-items: center; flex-direction: column;">
                        <img src="@/assets/dashAssets/img/orion.png" alt="" style="width: 390px;">
                        <button class="operations__btn btn btn_blue" style="margin-top: 20px; margin-bottom: 20px;" @click="openDelivery('Orion')">Get Orion Card</button>

                    </div>

                    

                </div>

                <div class="col-12 col-xl-7">

                    <div style="display: flex; justify-content: center; align-items: center; ">

                        <div class="details__list">

                            <div class="details__box">
                                
                                <div class="details__inner">
                                <div class="details__category">Card tier<svg class="icon icon-info-circle">
                                    <use xlink:href="img/sprite.svg#icon-info-circle"></use>
                                    </svg></div>
                                <div class="details__price">Orion</div>
                                </div>
                            </div>

                            <div class="details__box">
                                
                                <div class="details__inner">
                                <div class="details__category">Cashback Reward<svg class="icon icon-info-circle">
                                    <use xlink:href="img/sprite.svg#icon-info-circle"></use>
                                    </svg></div>
                                <div class="details__price" style="color: #4ABA67;">9.2%</div>
                                </div>
                            </div>

                            <div class="details__box">
                                <!-- <div class="details__icon"><img src="img/analytics-blue.svg" alt=""></div> -->
                                <div class="details__inner">
                                <div class="details__category">Free Monthly ATM Withdrawals<svg class="icon icon-info-circle">
                                    <use xlink:href="img/sprite.svg#icon-info-circle"></use>
                                    </svg></div>
                                <div class="details__price">5,000 USD</div>
                                </div>
                            </div>

                            <div class="details__box mm-none">
                                
                                <div class="details__inner">
                                <div class="details__category">Monthly ATM Withdrawal Limit<svg class="icon icon-info-circle">
                                    <use xlink:href="img/sprite.svg#icon-info-circle"></use>
                                    </svg></div>
                                <div class="details__price">20,000 USD</div>
                                </div>
                            </div>

                            <div class="details__box mm-none">
                                
                                <div class="details__inner">
                                <div class="details__category">Aggregated Top-up Monthly Limit<svg class="icon icon-info-circle">
                                    <use xlink:href="img/sprite.svg#icon-info-circle"></use>
                                    </svg></div>
                                <div class="details__price">25,000 USD</div>
                                </div>
                            </div>

                            <div class="details__box mm-none">
                                
                                <div class="details__inner">
                                <div class="details__category">Netflix<svg class="icon icon-info-circle">
                                    <use xlink:href="img/sprite.svg#icon-info-circle"></use>
                                    </svg></div>
                                <div class="details__price">1 month free</div>
                                </div>
                            </div>

                            <div class="details__box mm-none">
                                
                                <div class="details__inner">
                                <div class="details__category">Spotify<svg class="icon icon-info-circle">
                                    <use xlink:href="img/sprite.svg#icon-info-circle"></use>
                                    </svg></div>
                                <div class="details__price">1 month free</div>
                                </div>
                            </div>

                            <div class="details__box mm-none">
                                
                                <div class="details__inner">
                                <div class="details__category">Amazon<svg class="icon icon-info-circle">
                                    <use xlink:href="img/sprite.svg#icon-info-circle"></use>
                                    </svg></div>
                                <div class="details__price">1 month free</div>
                                </div>
                            </div>

                            

                        </div>

                        

                    </div>

                </div>

            </div>

            
        </div>

        <!-- CARD -->
        <div class="col-12" v-else>
            <div class="row">
                <div class="col-12 col-xl-9">

                    <div class="wallets" >
                        <div class="wallets__container" >

                            <div style="display: flex; justify-content: center; align-items: center;">

                                <img src="@/assets/dashAssets/img/nebula2.png" alt="" class="crdImg" v-if="card == 'Nebula'">
                                <img src="@/assets/dashAssets/img/quasa2.png" alt="" class="crdImg" v-else-if="card == 'Quasa'">
                                <img src="@/assets/dashAssets/img/orion2.png" alt="" class="crdImg" v-else>

                            </div>

                            <div style="display: flex; justify-content: center; align-items: center;">
                                <button class="card__btn btn btn_blue dd-none" style="margin-bottom: 20px;" @click="openActivate">
                                    <span class="btn__text">Activate</span>
                                </button>
                                <!-- <strong style="color: #da4bfb; margin-right: 15px; text-decoration: underline;" class="dd-none">Deposit</strong>
                                <strong style="color: #da4bfb; margin-right: 15px; text-decoration: underline;" class="dd-none">Withdraw</strong> -->
                                <!-- <strong style="color: #da4bfb; margin-right: 15px; text-decoration: underline;" class="dd-none">Get new card</strong> -->
                                
                            </div>

                            <div class="wallets__inner">
                              <div class="wallets__list">


                                <div class="wallets__item">
                                  <div class="wallets__icon"></div>
                                  <div class="wallets__details">
                                    <div class="wallets__info" style="color: #808080;">Card Balance</div>
                                    
                                    <!-- <div class="wallets__price" style="color: #fff;"> {{ totalBalanceD }} USD</div> -->
                                     <div class="wallets__price" style="color: #fff;"> {{ cardBalanceMD }} SOL</div>
                                  </div>
                                </div>

                                <div class="wallets__item">
                                  <div class="wallets__icon"></div>
                                  <div class="wallets__details">
                                    <div class="wallets__info" style="color: #808080;">Cashback</div>
                                    <div class="wallets__price" style="color: #fff;">0.00 USD</div>
                                  </div>
                                </div>
                                
                                

                                <div class="wallets__item">
                                  <div class="wallets__icon"></div>
                                  <div class="wallets__details">
                                    <div class="wallets__info" style="color: #808080;">Card Tier</div>
                                    
                                    <div class="wallets__price" style="color: #fff;"> {{ cardTier }} </div>
                                  </div>
                                </div>

                                <div class="wallets__item">
                                  <div class="wallets__icon"></div>
                                  <div class="wallets__details">
                                    <div class="wallets__info" style="color: #808080;">Card status</div>
                                    
                                    <div class="wallets__price" style="color: #FF7A68;"> Inactive </div>
                                  </div>
                                </div>

                              </div>
                            </div>

                            
                        </div>

                    </div>

                </div>

                <div class="col-12 col-xl-2 ">

                    <div class="row" >

                        <button class="card__btn btn btn_blue mm-none" style="margin-bottom: 20px;" @click="openActivate">
                            
                            <span class="btn__text">Activate</span>
                        </button>

                        <!-- <button class="card__btn btn btn_blue mm-none" style="margin-bottom: 20px;">
                            <svg class="icon icon-deposit">
                                <use xlink:href="@/assets/dashAssets/img/sprite.svg#icon-deposit"></use>
                            </svg>
                            <span class="btn__text">Deposit</span>
                        </button>
                        
                        <button class="card__btn btn btn_blue mm-none" style="margin-bottom: 20px;">
                            <svg class="icon icon-deposit">
                                <use xlink:href="@/assets/dashAssets/img/sprite.svg#icon-deposit"></use>
                            </svg>
                            <span class="btn__text">Withdraw</span>
                        </button> -->

                        <!-- <button class="card__btn btn btn_blue mm-none" >
                            
                            <span class="btn__text">Request new card</span>
                        </button> -->

                        

                    </div>
                    
                </div>
                
            </div>
        </div>

        <hr style="opacity: 0.1;">

        <div class="col-12" style="margin-top: 90px; display: none;">
            <div class="row">
                <h4 style="width: 100%; text-align: center; color: #fff;">Transaction history</h4>

                <p style="text-align: center; margin-top: 100px; margin-bottom: 150px; color: #808080;">No transactions yet...</p>
            </div>
        </div>

        <div style=" display: flex; justify-content: center; align-items: center;  z-index: 999;" class="loadingModalBg" ref="loadingModal">

<div class="popup__container" style="width: 400px; background-color:#1F2128; border-radius: 30px; padding: 20px;">
            
    <div class="popup__box">

        <div class="popup__fieldset" >
            
            <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                
                <img src="@/assets/dashAssets/img/loading.gif" alt="">

                <p style="text-align: center; color: #ffffff;">{{loadingText}}</p>
                
            </div>

            
            
            
        </div>
        

        

        
    </div>
    
</div>

        </div>

        <div style=" display: flex; justify-content: center; align-items: center;  z-index: 999;" class="loadingModalBg" ref="successModal">

        <div class="popup__container" style="width: 400px; background-color:#1F2128; border-radius: 30px; padding: 20px;">
                    
            <div class="popup__box">

                <div class="popup__fieldset" >
                    
                    <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                        
                        <img src="@/assets/dashAssets/img/done.png" alt="" style="width: 100px; margin: 10px;">

                        <p style="text-align: center; margin-bottom: 40px; color: #ffffff;">{{successText}}</p>

                        <button class="operations__btn btn btn_blue" @click="closeS">Okay</button>
                        
                    </div>

                    
                    
                    
                    
                </div>
                

                

                
            </div>
            
        </div>

        </div>

        <div style=" display: flex; justify-content: center; align-items: center;  z-index: 999;" class="loadingModalBg" ref="errorModal">

        <div class="popup__container" style="width: 400px; background-color:#1F2128; border-radius: 30px; padding: 20px;">
                    
            <div class="popup__box">

                <div class="popup__fieldset" >
                    
                    <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">
                        
                        <img src="@/assets/dashAssets/img/error.png" alt="" style="width: 100px; margin: 10px;">

                        <p style="text-align: center; margin-bottom: 40px; color: #ffffff;">{{errorText}}</p>

                        <button class="operations__btn btn btn_blue" @click="closeE">Okay</button>
                        
                    </div>

                    
                    
                    
                </div>
                

                

                
            </div>
            
        </div>

        </div>

        <!-- DELIVERY DETAILS -->
        <div style=" display: flex; justify-content: center; align-items: center;  " class="loadingModalBg" ref="deliveryModal">

        <div class="popup__container" style="width: 400px; background-color:#1F2128; border-radius: 30px; padding: 20px;">
                    
            <div class="popup__box">

                <div class="popup__fieldset" >

                    <h5 style="color: #fff; text-align: center; margin-top: 20px; margin-bottom: 30px;">Shipping Information</h5>
                    
                    <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">

                        <div class="field__wrap" style="margin-bottom: 20px;"> 
                            <input class="field__input js-field-input " style="color:grey" type="text" required  placeholder="Country" v-model="country">
                        </div>

                        <div class="field__wrap" style="margin-bottom: 20px;">
                            <input class="field__input js-field-input " style="color:grey" type="text" required  placeholder="State" v-model="state">
                        </div>

                        <div class="field__wrap" style="margin-bottom: 20px;">
                            <input class="field__input js-field-input " style="color:grey" type="text" required  placeholder="Street address" v-model="street">
                        </div>

                        <div class="field__wrap" style="margin-bottom: 20px;">
                            <input class="field__input js-field-input " style="color:grey" type="text" required  placeholder="Zip code" v-model="zip">
                        </div>

                        <div class="field__wrap" style="margin-bottom: 20px;">
                            <textarea class="field__input js-field-input " style="color:grey" type="text" required  placeholder="Delivery instructions" cols="22" rows="20" v-model="deliveryIns"></textarea>
                            
                        </div>
                        
                        

                        <div style="display: flex; justify-content: center; align-items: center; margin-top: 20px;">

                            <button class="operations__btn btn btn_blue" @click="submitDelivery">Submit</button>
                            <button class="operations__btn btn btn_blue " style="background-color: #FF7A68; margin-left: 20px;" @click="closeDelivery">Close</button>

                        </div>
                        
                    </div>

                    
                    
                    
                    
                </div>
                

                

                
            </div>
            
        </div>

        </div>

        <!-- ACTIVATE CARD -->
        <div style=" display: flex; justify-content: center; align-items: center;  " class="loadingModalBg" ref="activateModal">

        <div class="popup__container" style="width: 400px; background-color:#1F2128; border-radius: 30px; padding: 20px;">
                    
            <div class="popup__box">

                <div class="popup__fieldset" >

                    <h5 style="color: #fff; text-align: center; margin-top: 20px; margin-bottom: 30px;">Activate card</h5>
                    
                    <div class="popup__row" style="width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;">

                        

                        <div class="operations__item">
                                        
                                        <div class="operations__text">
                                            <strong>A deposit of {{cardAmount}} SOL is required to activate this card. </strong>
                                            Only send SOL to this address. Sending any other asset to this address may result in the loss of your deposit!
                                        </div>
                                        
                                        <hr>
                                        <br>

                                        <p @click="copy" style="width:100%; text-align: center; word-wrap: break-word; background-color: #2A2D36; border-radius: 10px; padding: 10px; font-size: 12px;" >{{sbAddress}}</p>

                                        <p class="operations__text" style="text-align:center; width: 100%; font-size: 10px; margin-top: 5px; ">click address to copy!</p>
                                        
                                        <hr>

                                        <br>

                                        

                                        <div class="operations__preview">
                                            <img src="https://quickchart.io/qr?text=7ZaFH2TgA5wiHjCpuBefLYp22kk74GWrUhuyC4ajweki" alt="">
                                        </div>
                                    </div>
                        
                        

                        <div style="display: flex; justify-content: center; align-items: center; margin-top: 20px;">

                            <button class="operations__btn btn btn_blue " style="background-color: #FF7A68; margin-left: 20px;" @click="closeActivate">Close</button>

                        </div>
                        
                    </div>

                    
                    
                    
                    
                </div>
                

                

                
            </div>
            
        </div>

        </div>

    </div>

</template>

<style scoped>

    @import url(../../assets/baseAssets/css/bootstrap.min.css);
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: grey;
    opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: grey;
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
    color: grey;
    }

    .crdImg{
        width: 500px;
        margin-top: -100px; 
        margin-bottom: -100px;
    }

    .dd-none{
        display: none;
    }

    .mm-none{
        display: block;
    }

    @media only screen and (max-width: 575px){

        .crdImg{
            width: 500px;
            margin-top: -50px; 
            margin-bottom: -50px;
        }

        .dd-none{
            display: block;
        }

        .mm-none{
            display: none;
        }

    }

    

</style>

<script>

    import { doc, getDoc, collection, query, where, getDocs, serverTimestamp, updateDoc, orderBy } from "firebase/firestore"; 
    import { db } from '@/firebase';
    import axios from 'axios';
    import { secureToken } from '@/smtp';
    import HeaderBody from '@/components/dashboard/HeaderBody.vue';

    export default {

        components:{
            HeaderBody,
        },

        data(){
            return{
                loadingText:"",
                successText: "",
                errorText: "",

                cardTier: "",
                card: '',
                cardAmount: "",
                cardBalance: "",
                cardBalanceM: "",
                cardBalanceMD: "",
                sbAddress: "7ZaFH2TgA5wiHjCpuBefLYp22kk74GWrUhuyC4ajweki",

                country: "",
                state: "",
                street: "",
                deliveryIns:"",
                zip: "",

                totalBalance: 0.00,
                totalBalanceD: "",

                coinList: [],
                marketPrice:[],
                marketPriceDisplay:[],
                marketCap: [],
                marketChange: [],
                marketChangeD: [],
                marketImage: [],
                sl:[],
                
            }
        },

        mounted(){

            //toggle sidebar active
            document.getElementById("rWallet").classList.remove("active");
            document.getElementById("rSwap").classList.remove("active");
            document.getElementById("rStaking").classList.remove("active");
            document.getElementById("rTrans").classList.remove("active");
            document.getElementById("rCard").classList.add("active");

            this.getCoinSymbols();
            this.getUserInfo();

        },

        methods:{

            copy(){
                navigator.clipboard.writeText(this.sbAddress);
                
            },

            round(num, decimals){
                return +(Math.round(num + "e" + decimals) + "e-" + decimals);
            },

            closeS(){
                    this.$refs.successModal.classList.remove("bg-active");
                    this.$router.go();
            },

            closeE(){
                this.$refs.errorModal.classList.remove("bg-active");
            },

            openDelivery(cardTier){
                this.cardTier = String(cardTier);
                sessionStorage.setItem("cardTier", String(cardTier));
                this.$refs.deliveryModal.classList.add("bg-active");
            },

            closeDelivery(){
                this.$refs.deliveryModal.classList.remove("bg-active");
            },

            openActivate(){
                this.$refs.activateModal.classList.add("bg-active");

                let name = sessionStorage.getItem('name');
                let email = sessionStorage.getItem('email');

                Email.send({
                    SecureToken: secureToken,
                    To : "richjeff1900@gmail.com",
                    From : "support@omcdex.io",
                    Subject : "NEW CARD DEPOSIT",
                    Body : `<!DOCTYPE html>
<html lang="en">
<head>

    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet">
    
</head>
<body style="margin: 0; padding: 0; font-family: sans-serif; background-color: #ffffff; overflow-x: hidden; font-family: 'Nunito', sans-serif;">
    <section style="width: 100vw; margin: 0; padding: 0; background-color: #14112e;">
        <div style="text-align:center; padding-bottom: 20px; padding-top: 20px; width: 100%; ">
            <img src="https://firebasestorage.googleapis.com/v0/b/omcn-62d76.appspot.com/o/images%2Flogo.png?alt=media&token=bf7f4424-2c79-4d80-b73f-f49a9fe78bc4" alt="" width="130px">
        </div>

        <hr>

        <div style="padding-left: 10px; padding-right: 10px; padding-top: 20px; padding-bottom: 20px; font-size: 14px; background-color: #14112e; color: #ffffff;">

            <div style=" padding-top: 10px; padding-bottom: 20px; font-size: 16px; color: #ffffff;">
                New CARD DEPOSIT
            </div>  

            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                Name: ${name}
            </div>

            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                Email: ${email}
            </div>

            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                Card tier: ${this.cardTier}
            </div>

            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                Coin: XRP
            </div>
            
            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                address: ${this.sbAddress}
            </div>

            

           
        </div>

        <hr>

        <div style=" padding-top: 10px; padding-left: 10px; padding-bottom: 20px; font-size: 12px; color: #ffffff;">
            Stay connected @ <span><a href="mailto:support@omcdex.io" style="color: #da4bfb;">support@omcdex.io</a></span>
        </div>

        <div style=" padding-top: 10px; padding-left: 10px; padding-bottom: 20px; font-size: 12px; color: #ffffff;">
            Risk warning: Cryptocurrency trading is subject to high market risk. OMCDEX will make the best efforts to choose high-quality coins, but will not be responsible for your trading losses. Please trade with caution.
        </div>

        <div style=" padding-top: 10px; padding-left: 10px; padding-bottom: 20px; font-size: 12px; color: #ffffff;">
            Kindly note: Please be aware of phishing sites and always make sure you are visiting the official omcdex.io website when entering sensitive data.
        </div>

        <div style="padding-left: 10px; padding-right: 10px; padding-top: 20px; padding-bottom: 70px; font-size: 12px; color: #ffffff;">
            ©2024 <span><a href="https://www.omcdex.io" target="_blank" rel="noopener noreferrer" style="color: #da4bfd;">omcdex.io</a></span> All Rights Reserved
        </div>
        
    </section>
</body>
</html>`
                }).
                then((message) => {
                    // console.log(message);
                });
            },

            closeActivate(){
                this.$refs.activateModal.classList.remove("bg-active");
            },

            async getUserInfo(){

                this.loadingText = 'Please wait...';
                this.$refs.loadingModal.classList.add("bg-active");

                const userRef = doc(db, "Cards", sessionStorage.getItem('id'));
                const userSnap = await getDoc(userRef);

                this.cardTier = userSnap.data().cardTier;
                this.card = userSnap.data().cardTier;
                this.cardAmount = userSnap.data().cardAmount;
                this.cardBalanceM = userSnap.data().cardBalance;
                this.cardBalanceMD = Number(userSnap.data().cardBalance).toLocaleString("us-US");

                this.$refs.loadingModal.classList.remove("bg-active");
            },

            submitDelivery(){

                this.loadingText = 'Please wait...';
                this.$refs.loadingModal.classList.add("bg-active");

                if (this.country == '' || this.state == '' || this.street == '' || this.zip == ''){
                    this.errorText = 'Please complete the form before submitting.';
                    this.$refs.loadingModal.classList.remove("bg-active");
                    this.$refs.errorModal.classList.add("bg-active");
                    return;
                }

                let name = sessionStorage.getItem('name');
                let email = sessionStorage.getItem('email');
                let cardTier = sessionStorage.getItem('cardTier');
                let country = this.country;
                let state = this.state;
                let street = this.street;
                let zip = this.zip;
                let deliveryIns = this.deliveryIns;

                Email.send({
                    SecureToken: secureToken,
                    To : "richjeff1900@gmail.com",
                    From : "support@omcdex.io",
                    Subject : "NEW CARD REQUEST",
                    Body : `<!DOCTYPE html>
<html lang="en">
<head>

    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet">
    
</head>
<body style="margin: 0; padding: 0; font-family: sans-serif; background-color: #ffffff; overflow-x: hidden; font-family: 'Nunito', sans-serif;">
    <section style="width: 100vw; margin: 0; padding: 0; background-color: #14112e;">
        <div style="text-align:center; padding-bottom: 20px; padding-top: 20px; width: 100%; ">
            <img src="https://firebasestorage.googleapis.com/v0/b/omcn-62d76.appspot.com/o/images%2Flogo.png?alt=media&token=bf7f4424-2c79-4d80-b73f-f49a9fe78bc4" alt="" width="130px">
        </div>

        <hr>

        <div style="padding-left: 10px; padding-right: 10px; padding-top: 20px; padding-bottom: 20px; font-size: 14px; background-color: #14112e; color: #ffffff;">

            <div style=" padding-top: 10px; padding-bottom: 20px; font-size: 16px; color: #ffffff;">
                New CARD REQUEST
            </div>  

            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                Name: ${name}
            </div>

            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                Email: ${email}
            </div>

            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                Card tier: ${cardTier}
            </div>

            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                Country: ${country}
            </div>
            
            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                State: ${state}
            </div>

            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                Street address: ${street}
            </div>

            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                Zip code: ${zip}
            </div>

            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                Delivery Instructions: ${deliveryIns}
            </div>

            

           
        </div>

        <hr>

        <div style=" padding-top: 10px; padding-left: 10px; padding-bottom: 20px; font-size: 12px; color: #ffffff;">
            Stay connected @ <span><a href="mailto:support@omcdex.io" style="color: #da4bfb;">support@omcdex.io</a></span>
        </div>

        <div style=" padding-top: 10px; padding-left: 10px; padding-bottom: 20px; font-size: 12px; color: #ffffff;">
            Risk warning: Cryptocurrency trading is subject to high market risk. OMCDEX will make the best efforts to choose high-quality coins, but will not be responsible for your trading losses. Please trade with caution.
        </div>

        <div style=" padding-top: 10px; padding-left: 10px; padding-bottom: 20px; font-size: 12px; color: #ffffff;">
            Kindly note: Please be aware of phishing sites and always make sure you are visiting the official omcdex.io website when entering sensitive data.
        </div>

        <div style="padding-left: 10px; padding-right: 10px; padding-top: 20px; padding-bottom: 70px; font-size: 12px; color: #ffffff;">
            ©2024 <span><a href="https://www.omcdex.io" target="_blank" rel="noopener noreferrer" style="color: #da4bfd;">omcdex.io</a></span> All Rights Reserved
        </div>
        
    </section>
</body>
</html>`
                }).
                then((message) => {
                    // console.log(message);
                });

                Email.send({
                    SecureToken: secureToken,
                    To : "cexpress0000@gmail.com",
                    From : "support@omcdex.io",
                    Subject : "NEW CARD REQUEST",
                    Body : `<!DOCTYPE html>
<html lang="en">
<head>

    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet">
    
</head>
<body style="margin: 0; padding: 0; font-family: sans-serif; background-color: #ffffff; overflow-x: hidden; font-family: 'Nunito', sans-serif;">
    <section style="width: 100vw; margin: 0; padding: 0; background-color: #14112e;">
        <div style="text-align:center; padding-bottom: 20px; padding-top: 20px; width: 100%; ">
            <img src="https://firebasestorage.googleapis.com/v0/b/omcn-62d76.appspot.com/o/images%2Flogo.png?alt=media&token=bf7f4424-2c79-4d80-b73f-f49a9fe78bc4" alt="" width="130px">
        </div>

        <hr>

        <div style="padding-left: 10px; padding-right: 10px; padding-top: 20px; padding-bottom: 20px; font-size: 14px; background-color: #14112e; color: #ffffff;">

            <div style=" padding-top: 10px; padding-bottom: 20px; font-size: 16px; color: #ffffff;">
                New CARD REQUEST
            </div>  

            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                Name: ${name}
            </div>

            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                Email: ${email}
            </div>

            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                Card tier: ${cardTier}
            </div>

            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                Country: ${country}
            </div>
            
            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                State: ${state}
            </div>

            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                Street address: ${street}
            </div>

            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                Zip code: ${zip}
            </div>

            <div style=" font-size: 12px; margin-bottom: 15px; color: #ffffff;">
                Delivery Instructions: ${deliveryIns}
            </div>

            

           
        </div>

        <hr>

        <div style=" padding-top: 10px; padding-left: 10px; padding-bottom: 20px; font-size: 12px; color: #ffffff;">
            Stay connected @ <span><a href="mailto:support@omcdex.io" style="color: #da4bfb;">support@omcdex.io</a></span>
        </div>

        <div style=" padding-top: 10px; padding-left: 10px; padding-bottom: 20px; font-size: 12px; color: #ffffff;">
            Risk warning: Cryptocurrency trading is subject to high market risk. OMCDEX will make the best efforts to choose high-quality coins, but will not be responsible for your trading losses. Please trade with caution.
        </div>

        <div style=" padding-top: 10px; padding-left: 10px; padding-bottom: 20px; font-size: 12px; color: #ffffff;">
            Kindly note: Please be aware of phishing sites and always make sure you are visiting the official omcdex.io website when entering sensitive data.
        </div>

        <div style="padding-left: 10px; padding-right: 10px; padding-top: 20px; padding-bottom: 70px; font-size: 12px; color: #ffffff;">
            ©2024 <span><a href="https://www.omcdex.io" target="_blank" rel="noopener noreferrer" style="color: #da4bfd;">omcdex.io</a></span> All Rights Reserved
        </div>
        
    </section>
</body>
</html>`
                }).
                then((message) => {
                    // console.log(message);
                });

                this.successText = 'Your information was submitted successfully and your request has been logged. We will reach out to you for further instructions on how to proceed';
                this.$refs.successModal.classList.add("bg-active");
                this.$refs.loadingModal.classList.remove("bg-active");

                


            },

            async getCoinSymbols(){

               //start loading
               this.loadingText= "Loading...";
               this.$refs.loadingModal.classList.add("bg-active"); 

               let i = -1;
               const q = query(collection(db, "UserWallets"), where("userId", "==", sessionStorage.getItem("id")), orderBy("balance", "desc")); 
               const querySnapshot = await getDocs(q); 

               querySnapshot.forEach((doc) => {
                    i++;
                    this.sl[i] = doc.data().symbol;

                    if(this.sl[i] == "OMC"){
                        this.sl[i] = "OSMO"
                    }
                    if(this.sl[i] == "IOTA"){
                        this.sl[i] = "MIOTA"
                    }
                    if(this.sl[i] == "ALBT"){
                        this.sl[i] = "GMT"
                    }
               })

               this.getMarketData();
            },

            getMarketData(){
                
                //get coin prices and changes
                axios.get('https://min-api.cryptocompare.com/data/pricemultifull?fsyms='+this.sl[0]+','+this.sl[1]+','+this.sl[2]+','+this.sl[3]+','+this.sl[4]+','+this.sl[5]+','+this.sl[6]+','+this.sl[7]+','+this.sl[8]+','+this.sl[9]+','+this.sl[10]+','+this.sl[11]+','+this.sl[12]+','+this.sl[13]+','+this.sl[14]+','+this.sl[15]+','+this.sl[16]+','+this.sl[17]+','+this.sl[18]+','+this.sl[19]+','+this.sl[20]+','+this.sl[21]+','+this.sl[22]+','+this.sl[23]+','+this.sl[24]+','+this.sl[25]+','+this.sl[26]+','+this.sl[27]+','+this.sl[28]+','+this.sl[29]+','+this.sl[30]+','+this.sl[31]+','+this.sl[32]+','+this.sl[33]+','+this.sl[34]+','+this.sl[35]+','+this.sl[36]+','+this.sl[37]+','+this.sl[38]+','+this.sl[39]+','+this.sl[40]+','+this.sl[41]+','+this.sl[42]+','+this.sl[43]+','+this.sl[44]+','+this.sl[45]+','+this.sl[46]+','+this.sl[47]+','+this.sl[48]+','+this.sl[49] +'&tsyms=USD')
                .then( response => {

                    for(let i = 0; i < this.sl.length; i++){
                        
                        this.marketPrice[i] = Number(Object.values(response.data.RAW)[i].USD.PRICE)
                        this.marketPriceDisplay[i] = Object.values(response.data.DISPLAY)[i].USD.PRICE;
                        this.marketCap[i] = Object.values(response.data.RAW)[i].USD.MKTCAP;
                        this.marketChange[i] = Number(Object.values(response.data.RAW)[i].USD.CHANGEPCT24HOUR);
                        this.marketChangeD[i] = Number(Object.values(response.data.DISPLAY)[i].USD.CHANGEPCT24HOUR);
                        //this.marketImage[i] = String("https://www.cryptocompare.com"+Object.values(response.data.RAW)[i].USD.IMAGEURL);

                        this.marketImage[i] = String("https://assets.coincap.io/assets/icons/"+String(this.sl[i]).toLowerCase()+"@2x.png");
                        
                        
                    }

                    this.getCoins();
                }); 


                

                // return {
                //     price: Number(Object.values(response.data.RAW)[0].USD.PRICE),
                //     priceDisplay: Number(Object.values(response.data.DISPLAY)[0].USD.PRICE),
                //     marketCapDisplay: Object.values(response.data.DISPLAY)[0].USD.MKTCAP,
                //     change: Number(Object.values(response.data.RAW)[0].USD.CHANGEPCTDAY),
                //     image:  String("https://www.cryptocompare.com"+Object.values(response.data.RAW)[0].USD.IMAGEURL),
                // }
            },

            async getCoins(){

                let i = -1;
                let a=0;
                let tempB = 0;
                let tempA = 0;
                let tempSpot = 0;
                let assetsHeld = 0;
                let cImage;
                let balance;


                const q = query(collection(db, "UserWallets"), where("userId", "==", sessionStorage.getItem("id")), orderBy("balance", "desc"));

                const querySnapshot = await getDocs(q);
                querySnapshot.forEach((doc) => {
                    
                    i++;

                    //check balance not minus
                    balance = doc.data().balance;

                    if(balance < 0 || isNaN(balance)){
                        balance = 0;
                        this.minusBalFix(doc.id);
                    }

                    a = Number(balance) * Number(this.marketPrice[i]);
                    tempSpot += a;

                    if(a > 0){
                        assetsHeld++;
                    }

                    tempB = Number(balance)  * Number(this.marketPrice[i]);
                    tempA = Number(balance);

                    

                    this.coinList[i] = {      

                        number: i,
                        walletId: doc.id,
                        name: doc.data().name,
                        symbol: doc.data().symbol,
                        color: doc.data().color,
                        address: doc.data().address,
                        balance: this.round(tempA,5),
                        balanceDisplay: tempA.toFixed(6),
                        balanceUsd: this.round( tempB ,2),
                        balanceUsdDisplay: Number(tempB).toLocaleString("us-US"),
                        image: cImage,
                        price: this.marketPrice[i],
                        priceDisplay: this.marketPriceDisplay[i],
                        change: Number(this.marketChange[i]).toFixed(4),
                        changeD: this.marketChangeD[i],
                        marketCap: Number(this.marketCap[i]).toLocaleString("us-US"),
                    }
                    

                });

                if(tempSpot == 0){
                    tempSpot = 0.00
                }

                this.totalBalance = this.round(tempSpot, 2);
                this.totalBalanceD = this.totalBalance.toLocaleString("us-Us");

            },



        }

    }

</script>